import React from "react";
import { fileUploadIcon } from "../../../../assets";
import ReactDropzone from "../ReactDropzone";
import "./style.scss"; // Import the SCSS styles for DropBox design
import { FileError } from "react-dropzone";

interface DropBoxProps {
  title: string;
  sizeType: "MB" | "KB" | "GB";
  onDrop: (acceptedFiles: File[]) => void;
  maxFiles?: number;
  multiple?: boolean;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
  maxSize?: number;
  maxFileSize?: number;
}

const DropBox: React.FC<DropBoxProps> = ({
  title,
  maxSize,
  sizeType,
  onDrop,
  maxFiles,
  multiple = false,
  validator,
  maxFileSize,
}) => {
  return (
    <ReactDropzone
      onDrop={onDrop}
      maxFiles={maxFiles}
      maxSize={maxSize}
      validator={validator}
      multiple={multiple}
    >
      <div className="dragFile">
        <div className="dragIcon">
          <img
            src={fileUploadIcon}
            alt="upload"
          />
        </div>
        <h4 className="title">{title}</h4>
        <p className="text">{`(Max file size: ${maxFileSize} ${sizeType})`}</p>
      </div>
    </ReactDropzone>
  );
};

export default DropBox;
