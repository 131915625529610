import React from "react";
import { GenericComponentProps } from "../../../../interfaces/component";
import { SideMenubar } from "../../Layout";
import "./style.scss";

const Wrapper: React.FC<GenericComponentProps> = ({ children }) => {
  return (
    <div className="main-container">
      <aside className="sidebar">
        <SideMenubar />
      </aside>

      <main className="main">{children}</main>
    </div>
  );
};

export default Wrapper;
