import { Navigate, Route, Routes } from "react-router-dom";
import Default from "./Default";
import { authRoutes, privateRoutes, publicRoutes, ROUTER } from "./routes";

type RouteType = {
  isAuthenticated: boolean;
};

const AppRouter: React.FC<RouteType> = ({ isAuthenticated }) => {
  const publicPageRoutes = publicRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={component}
      />
    );
  });

  const authPageRoutes = authRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={component}
      />
    );
  });

  const privatePageRoutes = privateRoutes.map(({ label, path, component }) => {
    return (
      <Route
        key={label}
        path={`${path}`}
        element={isAuthenticated ? component : <Navigate to={ROUTER.AUTH_LOGIN} />}
      />
    );
  });

  return (
    <Routes>
      <Route
        path="/"
        element={<Default />}
      >
        {/* public routes */}
        <>{publicPageRoutes}</>

        {/* auth routes */}
        <>{authPageRoutes}</>

        {/* private routes */}
        <>{privatePageRoutes}</>

        {/* catch all */}
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? ROUTER.LANDING_PAGE : ROUTER.AUTH_LOGIN} />}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
