import { Link } from "react-router-dom";
import { logo, textLogo } from "../../../../../assets";
import { Button, Typography } from "../../../../../components/common/Base";
import { ROUTER } from "../../../../../router/routes";
import "./style.scss";

const SimpleNavbar = () => {
  return (
    <>
      <nav className="simple-navbar navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link
            className="navbar-brand"
            to={ROUTER.LANDING_PAGE}
          >
            <img
              src={logo}
              alt="Logo"
              width={40}
              height={40}
              className="img-fluid logo me-3"
            />
            <img
              src={textLogo}
              alt="Text logo"
              className="img-fluid text-logo"
            />
          </Link>

          <div className="subscribe">
            <a
              className="text-light"
              href="#subscription-sec"
            >
              <Button color={"primary"}>
                <Typography variant="button-text">Subscribe</Typography>
              </Button>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SimpleNavbar;
