import { createElement, lazy, Suspense } from "react";
import Wrapper from "../components/common/components/Wrapper";

export enum ROUTER {
  LANDING_PAGE = "/",
  AUTH_SIGN_UP = "/auth/sign-up",
  AUTH_SIGN_UP_ACCOUNT = "/auth/sign-up/account",
  AUTH_SIGN_UP_PASSWORD = "/auth/sign-up/password",
  AUTH_VALIDATION = "/auth/validation",
  AUTH_LOGIN = "/auth/login",
  AUTH_LOGIN_EMAIL = "/auth/login/email",
  AUTH_FORGOT = "/auth/forgot",
  AUTH_METHOD = "/auth/forgot/method",
  AUTH_FORGOT_PASSWORD = "/auth/forgot/password",
  AUTH_FORGOT_SUCCESS = "/auth/forgot/success",
  AUTH_BY_EMAIL = "/auth/email",
  BOOKING = "/booking",
  BOOKING_ITEM = "/bookingItem",
  MESSAGER = "/messager",
  SUBSCRIPTION_CONFIRMATION = "/subscription-confirmation",
  CAREERS = "/careers",
  COMMON_QUESTIONS = "/questions/common",
  CUSTOMERS = "/customers",
  SUPER_PRODUCERS = "/super-producers",
  SEE_STORIES = "/stories",
  WHATS_NEW = "/whats-new",
  CLIENT_REVIEW = "/reviews",
  ACCOUNTS = "/accounts",
  ACCOUNT_DETAILS_SUPERPRODUCER = "/account-details-superproducer",
  ACCOUNT_DETAILS_CREATOR = "/account-details-creator",
  ACCOUNT_DETAILS_CLIENTS = "/account-details-clients",
  ACCOUNT_DETAILS_MANAGER = "/account-details-manager",
  ONBOARDING = "/onboarding",
  CONTACT_FORM = "/contact-form",
  CONTACT_FORM_CONFIRMATION = "/contact-form-confirmation",
  DASHBOARD = "/dashboard",
  PAYMENT = "/payment",
  PROJECTS = "/projects",
  REFERRALS = "/referrals",
  PROFILE = "/profile",
  SETTINGS = "/settings",
  FAVORITE = "/favorite",
  CREATORS = "/creators",
  CREATIVES_PROFILE = "/creatives-profile/:uuid",
}

const routes = {
  landingPage: {
    path: ROUTER.LANDING_PAGE,
    label: "Landing Page",
    component: <Suspense>{createElement(lazy(() => import("../pages/landing-page")))}</Suspense>,
  },
  welcomeSignUp: {
    path: ROUTER.AUTH_SIGN_UP,
    label: "Welcome SignUp",
    component: <Suspense>{createElement(lazy(() => import("../pages/welcomeSignUp")))}</Suspense>,
  },
  verificationCode: {
    path: ROUTER.AUTH_VALIDATION,
    label: "Verification Code",
    component: <Suspense>{createElement(lazy(() => import("../pages/verificationCode")))}</Suspense>,
  },
  signInEmail: {
    path: ROUTER.AUTH_LOGIN,
    label: "Login with Email",
    component: <Suspense>{createElement(lazy(() => import("../pages/signInEmail")))}</Suspense>,
  },
  signInEmailPassword: {
    path: ROUTER.AUTH_LOGIN_EMAIL,
    label: "Login with Email and Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/signInEmailPassword")))}</Suspense>,
  },
  createAccount: {
    path: ROUTER.AUTH_SIGN_UP_ACCOUNT,
    label: "Create Account",
    component: <Suspense>{createElement(lazy(() => import("../pages/createAccount")))}</Suspense>,
  },
  createPassword: {
    path: ROUTER.AUTH_SIGN_UP_PASSWORD,
    label: "Create Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/createPassword")))}</Suspense>,
  },
  findAccount: {
    path: ROUTER.AUTH_FORGOT,
    label: "Forgot Account",
    component: <Suspense>{createElement(lazy(() => import("../pages/findAccount")))}</Suspense>,
  },
  chooseMethod: {
    path: ROUTER.AUTH_METHOD,
    label: "Choose Method",
    component: <Suspense>{createElement(lazy(() => import("../pages/chooseMethod")))}</Suspense>,
  },
  createNewPassword: {
    path: ROUTER.AUTH_FORGOT_PASSWORD,
    label: "Create New Password",
    component: <Suspense>{createElement(lazy(() => import("../pages/createNewPassword")))}</Suspense>,
  },
  passwordChangeSuccess: {
    path: ROUTER.AUTH_FORGOT_SUCCESS,
    label: "Password Change Success",
    component: <Suspense>{createElement(lazy(() => import("../pages/passwordChangeSuccess")))}</Suspense>,
  },
  subscriptionConfirmation: {
    path: ROUTER.SUBSCRIPTION_CONFIRMATION,
    label: "Subscription Confirmation",
    component: <Suspense>{createElement(lazy(() => import("../pages/subscription-confirmation")))}</Suspense>,
  },
  careers: {
    path: ROUTER.CAREERS,
    label: "Careers",
    component: <Suspense>{createElement(lazy(() => import("../pages/careers")))}</Suspense>,
  },
  commonQuestions: {
    path: ROUTER.COMMON_QUESTIONS,
    label: "Common Questions",
    component: <Suspense>{createElement(lazy(() => import("../pages/common-questions")))}</Suspense>,
  },
  customers: {
    path: ROUTER.CUSTOMERS,
    label: "Customers",
    component: <Suspense>{createElement(lazy(() => import("../pages/customers")))}</Suspense>,
  },
  superProducers: {
    path: ROUTER.SUPER_PRODUCERS,
    label: "Super Producers",
    component: <Suspense>{createElement(lazy(() => import("../pages/super-producers")))}</Suspense>,
  },
  seeStories: {
    path: ROUTER.SEE_STORIES,
    label: "See Stories",
    component: <Suspense>{createElement(lazy(() => import("../pages/see-stories")))}</Suspense>,
  },
  whatsNew: {
    path: ROUTER.WHATS_NEW,
    label: "Whats New",
    component: <Suspense>{createElement(lazy(() => import("../pages/whats-new")))}</Suspense>,
  },
  clientReview: {
    path: ROUTER.CLIENT_REVIEW,
    label: "Client Review",
    component: <Suspense>{createElement(lazy(() => import("../pages/clientReview")))}</Suspense>,
  },
  accounts: {
    path: ROUTER.ACCOUNTS,
    label: "Accounts",
    component: <Suspense>{createElement(lazy(() => import("../pages/accounts")))}</Suspense>,
  },
  accountDetails_SuperProducer: {
    path: ROUTER.ACCOUNT_DETAILS_SUPERPRODUCER,
    label: "Account Details - SuperProducer",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_SuperProducer")))}</Suspense>,
  },
  accountDetails_Creator: {
    path: ROUTER.ACCOUNT_DETAILS_CREATOR,
    label: "Account Details - Creator",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Creator")))}</Suspense>,
  },
  accountDetails_Clients: {
    path: ROUTER.ACCOUNT_DETAILS_CLIENTS,
    label: "Account Details - Clients",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Clients")))}</Suspense>,
  },
  accountDetails_Manager: {
    path: ROUTER.ACCOUNT_DETAILS_MANAGER,
    label: "Account Details - Manager",
    component: <Suspense>{createElement(lazy(() => import("../pages/accountDetails_Manager")))}</Suspense>,
  },
  onboarding: {
    path: ROUTER.ONBOARDING,
    label: "onboarding",
    component: <Suspense>{createElement(lazy(() => import("../pages/onBoarding_AfterSignUp")))}</Suspense>,
  },
  contactForm: {
    path: ROUTER.CONTACT_FORM,
    label: "Contact Form",
    component: <Suspense>{createElement(lazy(() => import("../pages/contactForm")))}</Suspense>,
  },
  contactFormConfirmation: {
    path: ROUTER.CONTACT_FORM_CONFIRMATION,
    label: "Contact Form Confirmation",
    component: <Suspense>{createElement(lazy(() => import("../pages/contactFormConfirmation")))}</Suspense>,
  },
  booking: {
    path: ROUTER.BOOKING,
    label: "Booking",
    component: <Suspense>{createElement(lazy(() => import("../pages/booking")))}</Suspense>,
  },
  bookingDetail: {
    path: ROUTER.BOOKING_ITEM,
    label: "BookingItemDetail",
    component: <Suspense>{createElement(lazy(() => import("../pages/bookingItemDetail")))}</Suspense>,
  },
  messenger: {
    path: ROUTER.MESSAGER,
    label: "Messager",
    component: <Suspense>{createElement(lazy(() => import("../pages/messager")))}</Suspense>,
  },
  dashboard: {
    path: ROUTER.DASHBOARD,
    label: "Clients Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/dashboard")))}</Wrapper>
      </Suspense>
    ),
  },
  payment: {
    path: ROUTER.PAYMENT,
    label: "Clients Payment Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/payment")))}</Wrapper>
      </Suspense>
    ),
  },
  projects: {
    path: ROUTER.PROJECTS,
    label: "Clients Projects Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/projects")))}</Wrapper>
      </Suspense>
    ),
  },
  referrals: {
    path: ROUTER.REFERRALS,
    label: "Clients referral Dashboard",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/referrals")))}</Wrapper>
      </Suspense>
    ),
  },
  profile: {
    path: ROUTER.PROFILE,
    label: "Profile page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/profilePage")))}</Wrapper>
      </Suspense>
    ),
  },
  setting: {
    path: ROUTER.SETTINGS,
    label: "setting page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("../pages/settingPage")))}</Wrapper>
      </Suspense>
    ),
  },
  favorite: {
    path: ROUTER.FAVORITE,
    label: "favorite page",
    component: (
      <Suspense>
        <Wrapper>{createElement(lazy(() => import("./../pages/favoritePage")))}</Wrapper>
      </Suspense>
    ),
  },
  authByEmailLink: {
    path: ROUTER.AUTH_BY_EMAIL,
    label: "Auth by Email Link",
    component: (
      <Suspense>
        <>{createElement(lazy(() => import("../pages/emailAuthentication")))}</>
      </Suspense>
    ),
  },
  creators: {
    path: ROUTER.CREATORS,
    label: "creators",
    component: <Suspense>{createElement(lazy(() => import("../pages/creators")))}</Suspense>,
  },
  creativesprofile: {
    path: ROUTER.CREATIVES_PROFILE,
    label: "Creatives Profile",
    component: <Suspense>{createElement(lazy(() => import("../pages/creativesProfile")))}</Suspense>,
  },
};

export const publicRoutes = [
  routes.accounts,
  // routes.comingSoon,
  routes.landingPage,
  routes.careers,
  routes.subscriptionConfirmation,
  routes.commonQuestions,
  routes.customers,
  routes.superProducers,
  routes.seeStories,
  routes.whatsNew,
  routes.clientReview,
  routes.contactForm,
  routes.contactFormConfirmation,
  routes.creators,
  routes.creativesprofile,
  routes.favorite,
];
export const authRoutes = [
  routes.welcomeSignUp,
  routes.createAccount,
  routes.verificationCode,
  routes.createPassword,
  routes.findAccount,
  routes.createNewPassword,
  routes.passwordChangeSuccess,
  routes.chooseMethod,
  routes.signInEmail,
  routes.signInEmailPassword,
  routes.authByEmailLink,
];
export const privateRoutes = [
  routes.accountDetails_SuperProducer,
  routes.accountDetails_Creator,
  routes.accountDetails_Clients,
  routes.onboarding,
  routes.accountDetails_Manager,
  routes.booking,
  routes.bookingDetail,
  routes.messenger,
  routes.dashboard,
  routes.payment,
  routes.profile,
  routes.setting,
  routes.projects,
  routes.referrals,
];
