import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist"; // redux-persist action types
import { baseApi } from "../api/apiClient";

export const store = configureStore({
  reducer: {
    // Add the base API reducer here
    [baseApi.reducerPath]: baseApi.reducer,
  },
  // Add middleware for API and handle non-serializable actions
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore persist actions
      },
    }).concat(baseApi.middleware),
});
