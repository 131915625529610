import React from "react";
import { useDropzone, DropzoneState, FileError } from "react-dropzone";

interface ReactDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  maxFiles?: number;
  minFiles?: number;
  multiple?: boolean;
  children: React.ReactNode;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
  maxSize?: number;
}

const ReactDropzone: React.FC<ReactDropzoneProps> = ({
  onDrop,
  maxFiles = 1,
  multiple = false,
  children,
  validator,
  maxSize,
}) => {
  const { getRootProps, getInputProps }: DropzoneState = useDropzone({
    maxFiles,
    multiple,
    onDrop,
    validator,
    maxSize,
  });

  return (
    <div {...getRootProps({ className: "dropzone-container" })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default ReactDropzone;
