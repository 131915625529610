import { Link } from "react-router-dom";
import { facebook, instagram, logo, textLogo, twitter, youtube } from "../../../../assets";
import { ROUTER } from "../../../../router/routes";
import { Accordion, Typography } from "../../Base";
import "./style.scss";

interface FooterProps {
  showLargeFooter?: boolean;
}

const Footer: React.FC<FooterProps> = ({ showLargeFooter }) => {
  return (
    <>
      {showLargeFooter && (
        <footer className="footer-container">
          <div className="container footer1">
            <div className="logo-name">
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  width={40}
                  height={40}
                  className="img-fluid"
                />
              </div>
              <div className="name">
                <img
                  src={textLogo}
                  alt="Text logo"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="res-footer1">
              <Accordion
                title={"Overview"}
                content={
                  <>
                    <ul className="accordion-list">
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Legacy</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Customers</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Marketplace</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">SuperProducers</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Creatives</Typography>
                        </Link>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title={"Resources"}
                content={
                  <>
                    <ul className="accordion-list">
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Legacy</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Customers</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Marketplace</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">SuperProducers®</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Creatives</Typography>
                        </Link>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title={"Company"}
                content={
                  <>
                    <ul className="accordion-list">
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Seminars</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Courses</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Forreels.io Speed date</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Success stories</Typography>
                        </Link>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title={"Support"}
                content={
                  <>
                    <ul className="accordion-list">
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Blog</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Careers</Typography>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="accordion-item"
                          to="#"
                        >
                          <Typography variant="small-text">Forreels.io awards</Typography>
                        </Link>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
            </div>

            <div className="footer1List">
              <ul className="list">
                <li className="listItem">
                  <Link
                    className="listHeading"
                    to="#"
                  >
                    <Typography variant="link-text">Overview</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Legacy</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to={ROUTER.CUSTOMERS}
                  >
                    <Typography variant="small-text">Customers</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Marketplace</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to={ROUTER.SUPER_PRODUCERS}
                  >
                    <Typography variant="small-text">Super Producers</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Creatives</Typography>
                  </Link>
                </li>
              </ul>
              <ul className="list">
                <li className="listItem">
                  <Link
                    className="listHeading"
                    to="#"
                  >
                    <Typography variant="link-text">Resources</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Seminars</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Courses</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Forreels.io Speed date</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to={ROUTER.SEE_STORIES}
                  >
                    <Typography variant="small-text">Success stories</Typography>
                  </Link>
                </li>
              </ul>
              <ul className="list">
                <li className="listItem">
                  <Link
                    className="listHeading"
                    to="#"
                  >
                    <Typography variant="link-text">Company</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Blog</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to={ROUTER.CAREERS}
                  >
                    <Typography variant="small-text">Careers</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Forreels.io awards</Typography>
                  </Link>
                </li>
              </ul>
              <ul className="list">
                <li className="listItem">
                  <Link
                    className="listHeading"
                    to="#"
                  >
                    <Typography variant="link-text">Support</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Contact Us</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to="#"
                  >
                    <Typography variant="small-text">Support</Typography>
                  </Link>
                </li>
                <li className="listItem">
                  <Link
                    className="subListHeading"
                    to={ROUTER.COMMON_QUESTIONS}
                  >
                    <Typography variant="small-text">Common Questions</Typography>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="divider"></div>
          <div className="container footer2">
            <ul className="footer2List">
              <li>
                <div className="listHeading logo-name">
                  <Link to="#">
                    <img
                      src={logo}
                      alt="logo"
                      className="logoImage2"
                    />
                  </Link>
                  <Typography variant="small-text">© 2023 Forreels. All rights reserved.</Typography>
                </div>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Terms</Typography>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Privacy</Typography>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Cookie Preferences</Typography>
              </li>
              <li className="listHeading">
                <Link
                  className="listHeading"
                  to="#"
                >
                  <Typography variant="small-text">Do not sell or share my personal information</Typography>
                </Link>
              </li>
            </ul>

            <div className="logos">
              <div className="logoStyle">
                <img
                  src={instagram}
                  alt="facebook"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={youtube}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={facebook}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={twitter}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
            </div>
          </div>
        </footer>
      )}

      {!showLargeFooter && (
        <>
          <div className="divider"></div>
          <div className="container footer2">
            <ul className="footer2List">
              <li>
                <div className="listHeading logo-name">
                  <Link to="#">
                    <img
                      src={logo}
                      alt="logo"
                      className="logoImage2"
                    />
                  </Link>
                  <Typography variant="small-text">© 2023 Forreels. All rights reserved.</Typography>
                </div>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Terms</Typography>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Privacy</Typography>
              </li>
              <li className="listHeading">
                <Typography variant="small-text">Cookie Preferences</Typography>
              </li>
              <li className="listHeading">
                <Link
                  className="listHeading"
                  to="#"
                >
                  <Typography variant="small-text">Do not sell or share my personal information</Typography>
                </Link>
              </li>
            </ul>

            <div className="logos">
              <div className="logoStyle">
                <img
                  src={instagram}
                  alt="facebook"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={youtube}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={facebook}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
              <div className="logoStyle">
                <img
                  src={twitter}
                  alt="youtube"
                  className="logoStyle"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
