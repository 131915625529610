import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API slice
export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
  }),
  tagTypes: ["fetchUserProfileTag", "fetchUserCategorySelectionsTag", "fetchUserLocationsTag", "handleCheckTag"],
  endpoints: () => ({}), // Endpoints are injected separately in other files
});
