import { baseApi } from "../apiClient";
import { MakeUserOld } from "./user.types";
import { User } from "../auth/auth.service";

interface checkUniqueName {
  isUnique: boolean;
}

export interface RoleTypes {
  id: number;
  uuid: string;
  title: string;
  description: string;
}

interface RoleOrder {
  order: ("Creator" | "Super Producer" | "Manager" | "Client")[];
}

// NOTE: Do not change the account role types
export const AccountRoleType = {
  creator: "Creator",
  superProducer: "Super Producer",
  manager: "Manager",
  client: "Client",
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setUserIsOld: builder.mutation<void, MakeUserOld>({
      query: (makeUserOld) => ({
        url: "/user/make-old",
        method: "POST",
        body: makeUserOld,
      }),
    }),
    fetchUserProfile: builder.query<User, void>({
      query: () => `/user/`,
      providesTags: ["fetchUserProfileTag"],
    }),
    updateUserProfile: builder.mutation<void, { data: User }>({
      query: (data) => ({
        url: `/user/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    deleteUserProfile: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    checkUniqueUsername: builder.mutation<checkUniqueName, string>({
      query: (userName) => ({
        url: `/user/check-unique-username/${userName}`,
        method: "POST",
      }),
    }),
    handleEmailUpdateVerification: builder.mutation<void, { newEmail: string; password: string }>({
      query: ({ newEmail, password }) => ({
        url: "/user/update/email",
        method: "POST",
        body: { newEmail, password },
      }),
    }),
    handleUpdateUserPassword: builder.mutation<{ message: string }, { oldPassword: string; newPassword: string }>({
      query: ({ oldPassword, newPassword }) => ({
        url: "/user/update/password",
        method: "PATCH",
        body: { oldPassword, newPassword },
      }),
    }),
    handleUpdateUserEmail: builder.mutation<void, string>({
      query: (token) => ({
        url: `/user/mail/change`,
        method: "POST",
        body: { token },
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    handleCloseAccount: builder.mutation<void, string>({
      query: (reason) => ({
        url: "/user/account/close",
        method: "POST",
        body: { reason },
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    activateClosedAccount: builder.mutation<void, string>({
      query: (password) => ({
        url: "/user/account/activate",
        method: "POST",
        body: { password },
      }),
      invalidatesTags: ["fetchUserProfileTag"],
    }),
    fetchUsersRoleTypes: builder.query<RoleTypes[], RoleOrder>({
      query: ({ order }) => `/user/types?order=${order.join(",")}`,
    }),
  }),
});

export const {
  useSetUserIsOldMutation,
  useFetchUserProfileQuery,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useCheckUniqueUsernameMutation,
  useHandleEmailUpdateVerificationMutation,
  useHandleUpdateUserPasswordMutation,
  useHandleUpdateUserEmailMutation,
  useHandleCloseAccountMutation,
  useActivateClosedAccountMutation,
  useFetchUsersRoleTypesQuery,
} = userApi;
