import { Spinner } from "react-bootstrap";
import { useHandleCheckQuery } from "../api/auth/auth.service";
import { useFetchUserProfileQuery } from "../api/user/user.service";
import ActivateAccountModel from "../components/models/ActivateAccountModel";
import AppRouter from "./AppRouter";
import PrivateContainer from "./PrivateContainer";
import PublicContainer from "./PublicContainer";

const DefaultLayout = () => {
  const { data: userProfile } = useFetchUserProfileQuery();
  const { data: handleCheckData, isLoading } = useHandleCheckQuery();
  const isAuth = handleCheckData?.authenticated ?? false;
  const accountStatusType = { active: "active", inActive: "in-active" };
  const currentUserStatus = userProfile?.accountStatus;

  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          {isAuth ? (
            <PrivateContainer>
              <AppRouter isAuthenticated={isAuth} />
              {currentUserStatus && currentUserStatus == accountStatusType.inActive && <ActivateAccountModel />}
            </PrivateContainer>
          ) : (
            <PublicContainer>
              <AppRouter isAuthenticated={isAuth} />
            </PublicContainer>
          )}
        </>
      )}
    </>
  );
};

export default DefaultLayout;
