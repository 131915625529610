import React from "react";
import "./styles.scss";
// import { ReactComponent as RightIcon } from "./../../../../assets/icons/rightIcon.svg";

interface RadioButtonProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id?: string;
  checked?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  iconType: "dot" | "check";
}

const RadioButton: React.FC<RadioButtonProps> = ({ value, onChange, name, id, checked, onBlur, iconType }) => {
  return (
    <>
      <input
        type="radio"
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        className="custom-radio-input"
        checked={checked}
        onBlur={onBlur}
      />
      {iconType == "dot" && <span className="custom-radio-checkmark"></span>}
      {iconType == "check" && <span className="radio-checkmark-icon"></span>}
      {/* <span className="custom-radio-checkmark"></span>
      <span className="radio-checkmark-icon"></span> */}
    </>
  );
};

export default RadioButton;
